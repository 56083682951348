<template>
    <CustomLoader v-if="loading" />
    <ReplyWithoutAccount
        v-else-if="!loading && surveyData"
        :surveyData="surveyData"
        @registerAndReplyNow="registerAndReplyNow"
    >
        <template #form>
            <ReplyContentForm
                :businessData="businessData"
                :userData="userData"
                :queryDataId="queryDataId"
                @openReplyNowPopup="openReplyNowPopup"
                @registerAndReplyNow="registerAndReplyNow"
            />
        </template>
    </ReplyWithoutAccount>

    <FeedbackReplyPopup
        v-if="feedbackDataContainer"
        :popupVisible="isFeedbackReplyPopupVisible"
        :feedbackDataContainer="feedbackDataContainer"
        @handleSaveOrSkip="handleSaveOrSkip"
        @onClose="handleCloseReplyFeedbackPopup"
    />
</template>

<script>
import ReplyWithoutAccount from "@/buying-teams/pages/business/feedbacks/reply-without-account/ReplyWithoutAccount";
import ReplyContentForm from "@/buying-teams/pages/business/feedbacks/reply-without-account/ReplyContentForm";
import store from "@/store";
import { ReplyFeedbackDataContainer } from "@/store/models/business/feedback/ReplyFeedbackDataContainer";
import FeedbackReplyPopup from "@/buying-teams/pages/business/feedbacks/feedback-reply/FeedbackReplyPopup";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import { SurveyModel } from "@/store/models/SurveyModel";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import ReplyActionTypeEnum from "@/store/enums/ReplyActionTypeEnum";
import { Mutations } from "@/store/enums/StoreEnums";

export default {
    name: "ReplyPage",
    data() {
        return {
            loading: true,
            queryDataId: null,
            isFeedbackReplyPopupVisible: false,
            feedbackDataContainer: null,
            surveyData: null,
            businessData: null,
            userData: null,
            ReplyActionTypeEnum
        };
    },
    components: {
        CustomLoader,
        FeedbackReplyPopup,
        ReplyContentForm,
        ReplyWithoutAccount
    },
    created() {
        if (!this.$route.query.id) {
            redirectToRouteWithName("sign-in");
            return;
        }
        if (this.isUserAuthenticated) {
            store.commit(Mutations.PURGE_AUTH);
        }
        this.queryDataId = this.$route.fullPath.substring(this.$route.fullPath.indexOf("=") + 1);
        store.dispatch("getSurveyDataForReplay", this.queryDataId).then(res => {
            if (res.survey) {
                this.feedbackDataContainer = new ReplyFeedbackDataContainer(res.survey);
                this.feedbackDataContainer.setSurveyDataId(this.queryDataId);
                this.surveyData = new SurveyModel(res.survey);
                this.businessData = res.business;
                this.userData = res.user;
                if (this.feedbackDataContainer.isCompleted) {
                    redirectToRouteWithName("sign-in");
                }
            }
        }).catch(() => {
            redirectToRouteWithName("sign-in");
        }).finally(() => {
            this.loading = false;
        });
    },
    methods: {
        handleCloseReplyFeedbackPopup() {
            if (this.feedbackDataContainer.isCompleted) {
                redirectToRouteWithName("sign-in");
                return;
            }
            this.isFeedbackReplyPopupVisible = false;
        },
        openReplyNowPopup() {
            this.isFeedbackReplyPopupVisible = true;
        },
        handleSaveOrSkip({ action, data }) {
            delete data.survey_id;
            data.data_id = this.queryDataId;
            this.feedbackDataContainer.setSubmittingLoadingStatus(true);
            store.dispatch(action === ReplyActionTypeEnum.REPLY ? "replyToSurveyNoAuth" : "skipQuestionNoAuth", data)
                .then(async (res) => {
                    if (res) {
                        if (res.is_survey_completed) {
                            await store.dispatch("getSurveysStatsNoAuth", this.queryDataId)
                                .then((res) => {
                                    this.feedbackDataContainer.setCompletedStats(res);
                                });
                            this.feedbackDataContainer.setCompleted();
                        } else {
                            this.feedbackDataContainer.nextQuestion();
                        }
                    }
                })
                .catch(err => {
                    console.error("handleSaveOrSkip ", err);
                })
                .finally(() => {
                    this.feedbackDataContainer.setSubmittingLoadingStatus(false);
                });
        },
        registerAndReplyNow() {
            redirectToRouteWithName("sign-in");
        }
    },
    computed: {
        isUserAuthenticated() {
            return store.getters.isUserAuthenticated;
        }
    }
};
</script>

<style scoped>

</style>
